
window.addEventListener('DOMContentLoaded', () => {
  /** Opens Need help dialog when clicks on need help button */
  document.querySelector('.js-need-help-open').addEventListener('click', () => {
    document.querySelector('.js-need-help-dialog').showModal();
  });

  document.querySelector('.js-need-help-close').addEventListener('click', () => {
    document.querySelector('.js-need-help-dialog').closeModal();
  }); 
  
  const zopimChatLink = document.querySelector('.js_zopim_chat_link');
  zopimChatLink?.addEventListener('click', () => {
    const url = zopimChatLink.dataset.zopimChatLink;
    window.open(url, 'Chat', 'width=600, height=600');
  });
});
